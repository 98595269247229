<template src="./template.html"></template>

<script>
import GSAP from "@/common/GSAP/case_feature.js"
export default {
  name: "Feature",
  mixins: [GSAP],
  data() {
    return {
      jobs: [
        {
          image: "/img/case/004-real-estate-agent.png",
          title: "房仲業",
          content: "大量曝光、馬上聯絡<br>快、狠、準缺一不可。"
        },
        {
          image: "/img/case/006-car-insurance.png",
          title: "汽車產業",
          content: "口碑行銷第一步建立完整形象<br>更能導航到專屬位置賞車。"
        },
        {
          image: "/img/case/003-banking.png",
          title: "金融保險業",
          content: "多種不同聯絡管道，專業形象塑造<br>增加客戶信任度。"
        },
        {
          image: "/img/case/002-wedding.png",
          title: "婚紗業",
          content: "透過客戶的人脈推薦，<br>完整資訊一鍵傳送給朋友。"
        },
        {
          image: "/img/case/001-company.png",
          title: "企業品牌",
          content: "企業文化邁向全世界打造國際版圖<br>不需接觸即可展現更專業形象。"
        },
        {
          image: "/img/case/007-office.png",
          title: "個人工作室",
          content: "小成本大製作，建立個人形象，<br>符合專屬需求，更能拓展業務。"
        }
      ]
    }
  }
}
</script>
import gsap from "gsap"
import SmoothScrollResister from "@/common/GSAP/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            home_cover_gsap: null,
            home_video_text_gsap: null
        }
    },
    methods: {},
    mounted() {
        this.RegisterGsap("CasePreview")
        let vm = this

        vm.home_cover_gsap = gsap.timeline({
            scrollTrigger: {
                trigger: "#Feature",
                start: "top 80%",
                end: "bottom center",
                // pin: "#HomeFeatures .step",
                // pinSpacing: false,
                refreshPriority: 1
            }
        });

        let cards = document.querySelectorAll("#Feature .card_list .card")
        cards.forEach(item => {

            vm.home_cover_gsap.fromTo(item, {
                opacity: 0,
                y: "50px"
            }, {
                opacity: 1,
                y: "0",
                duration: .5
            }, 0)
        })

        this.InitGsapMarker()
    },
    beforeDestroy() {
        this.home_cover_gsap != null ? this.home_cover_gsap.kill() : ""
        this.home_video_text_gsap != null ? this.home_video_text_gsap.kill() : ""
    }
}
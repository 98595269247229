<template>
  <div id="CasePreview">
    <!-- banner -->
    <Banner />
    <!-- feature -->
    <Feature />
    <!-- case_list -->
    <List />
    <MainFooter />
  </div>
</template>

<script>
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import Banner from '@/components/CasePreview/Banner';
import Feature from '@/components/CasePreview/Feature';
import List from '@/components/CasePreview/List';
import MainFooter from '@/components/MainFooter';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'CasePreview',
  components: { Banner, Feature, List, MainFooter },
  mixins: [SmoothScrollResister],
  data() {
    return {
      meta_data: null,
    };
  },
  mounted() {
    this.RegisterGsap('CasePreview');
    window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData('案例分享', '', '');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>

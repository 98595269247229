import gsap from "gsap"
import SmoothScrollResister from "@/common/GSAP/SmoothScrollResister.js"
// import ScrollTrigger from "gsap/ScrollTrigger"


export default {
    mixins: [SmoothScrollResister],
    data() {
        return {
            home_cover_gsap: null,
            home_video_text_gsap: null
        }
    },
    methods: {},
    mounted() {
        this.RegisterGsap("CasePreview")
        // let vm = this

        // vm.home_cover_gsap = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: "#CasePreviewList",
        //         start: "top center",
        //         end: "bottom center",
        //         // pin: "#HomeFeatures .step",
        //         // pinSpacing: false,
        //         refreshPriority: 1
        //     }
        // });

        let cards = document.querySelectorAll("#CasePreviewList .card_list .card")
        cards.forEach((item, item_index) => {
            let timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    start: "top 80%",
                    end: "bottom center",
                    refreshPriority: 1,
                    toggleActions: "restart play play reverse"
                }
            })

            if (item_index % 2 == 0) {

                timeline.fromTo(item.querySelectorAll(".content_box"), {
                    opacity: 0,
                    x: "-50px"
                }, {
                    opacity: 1,
                    x: "0",
                    duration: .5
                }, 0)
                timeline.fromTo(item.querySelectorAll("img"), {
                    opacity: 0,
                    x: "50px"
                }, {
                    opacity: 1,
                    x: "0",
                    duration: .5
                }, 0)
            } else {

                timeline.fromTo(item.querySelectorAll(".content_box"), {
                    opacity: 0,
                    x: "50px"
                }, {
                    opacity: 1,
                    x: "0",
                    duration: .5
                }, 0)
                timeline.fromTo(item.querySelectorAll("img"), {
                    opacity: 0,
                    x: "-50px"
                }, {
                    opacity: 1,
                    x: "0",
                    duration: .5
                }, 0)
            }
        })

        this.InitGsapMarker()
    },
    beforeDestroy() {
        this.home_cover_gsap != null ? this.home_cover_gsap.kill() : ""
        this.home_video_text_gsap != null ? this.home_video_text_gsap.kill() : ""
    }
}
<template src="./template.html"></template>

<script>
import GSAP from "@/common/GSAP/case_list.js";
export default {
  name: "CasePreviewList",
  mixins: [GSAP],
  data() {
    return {
      case_list: [
        {
          image: "/img/case/S__295141418.jpg",
          title: "三信銀行專業形象融合新型數位",
          sub_title: "成為金融業的佼佼者挑戰著極限",
          name: "張慧珍業務襄理",
          content:
            "天天都會面臨新的客戶、業務將人名單好好歸類 是一件非常基本但也非常重要的事件，將人脈理 好人脈才會理你，這是我一位前輩跟我說的，而 很剛好有機會接觸到 MYii 個人形象網站，讓我可以更 加快速地整理好我的人脈，也因為有了它讓我在 顧客介紹起來更加的方便，讓我創造近年來前三 高的成交量。",
        },
        {
          image: "/img/case/bmw-2-series-gran-coupe-inspire-ag-sp-xxl.jpg",
          title: "BMW 銷售冠軍成功的利器",
          sub_title: "成為獨占鰲頭的道路必須有數位的資源",
          name: "陳信雄副理",
          content:
            "剛開始覺得 MYII 很新奇，對於每天都會發送至少 20 張名片的我，每兩個月幾乎都需要再重新印製 名片，在客戶推薦個人形象網站 MYii 時一操作後就愛 上了他。<br>在傳遞資訊、轉介紹客戶上都非常的加分，不需 要因為彼此之間沒有通訊軟件而錯失客戶的機 會，使用 MYII 歷經半年之久，業績成長了將近 45%，也因為這樣身邊朋友也都投以羨慕眼光。",
        },
        {
          image: "/img/case/S__295141417.jpg",
          title: "Honda 數位引進企業的益處",
          sub_title: "企業經營理念帶領更有未來發展性的理念",
          name: "許哲智銷售顧問",
          content:
            "因為日系的品牌更注重職場禮儀，在其中不可或 缺的就是第一次見面遞名片，因為企業為我們爭 取個人形象網站 MYii，讓我們更去思考數位化的轉型 可以更快速也更環保的去對待客戶，所以有了公 司的引進，讓我們可以比別人更有自盡的帶著數 位名片出門，更加可以需傳企業的理念，得到的 反饋都是極力認同也喜歡環保。",
        },
      ],
    };
  },
};
</script>
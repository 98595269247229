<template src="./template.html"></template>

<script>
import GSAP from "@/common/GSAP/case_banner.js"
export default {
  name: "CasePreviewBanner",
  mixins: [GSAP],
  data() {
    return {}
  },
  methods: {}
}
</script>